<template>
  <div class="pa-3">
    <div class="d-flex justify-center">
      <div class="col-12 col-md-5 col-lg-3">
        <h2>Bienvenido</h2>
        <p class="my-0">
          Generamos
          <span class="font-weight-bold">
            espacios que dinamizan la industria de las energías renovables
          </span>
          a través del relacionamiento, los negocios, la innovación y el
          conocimiento.
        </p>
        <h3
          class="mt-2 font-weight-bold primary--text"
          style="color: #5cb239 !important"
        >
          Da clic en la imagen del evento
          <v-icon large>mdi-arrow-down-bold</v-icon>
        </h3>
      </div>
    </div>
    <div class="d-flex justify-center">
      <div class="col-12 col-md-5 col-lg-3">
        <item-list-fairs
          v-for="(fair, index) in fairs"
          :key="index"
          :index="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ItemListFairs from "./components/ItemListFairs.vue";
import loaderMixin from "@/mixins/loaderMixin";
import store from "@/store";

export default {
  name: "Fairs",
  mixins: [loaderMixin],
  components: {
    ItemListFairs,
  },

  beforeRouteEnter(to, from, next) {
    if (
      store.getters["auth/authenticated"] &&
      store.getters["auth/userInfo"].user.reset_password == 1
    ) {
      next({ path: "/password-reset-confirm" });
    } else {
      next();
    }
  },
  computed: {
    totalFairs() {
      return this.$store.getters["fairs/total"];
    },
    fairs() {
      return this.$store.getters["fairs/fairs"];
    },
  },
  async mounted() {
    this.showLoader();
    await this.$store.dispatch("fairs/getFairs");
    this.hideLoader();
  },
};
</script>
